import React from "react";
import { graphql } from "gatsby";
import { className } from "../utils/helpers";
import Seo from "../components/shared/Seo";
import * as styles from "./styles/WorkPage.module.scss";

const WorkPage = ({ data }) =>
{
	const { data: pageData } = data.allPrismicWork.edges[0].node;

	return (
		<>
			<Seo
				title={pageData.title.text}
				description={pageData.description.text}
			/>

			<div id="como-trabajo" {...className(styles.workPage, "page-content")}>
				<div dangerouslySetInnerHTML={{ __html: pageData.section_content.html }} />
			</div>
		</>
	);
};

export const query = graphql`
	{
		allPrismicWork {
			edges {
				node {
					data {
						title {
							text
						}
						description {
							text
						}
						section_content {
							html
						}
					}
				}
			}
		}
	}
`;

export default WorkPage;
